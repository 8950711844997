import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { loginView: true },
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { loginView: true },
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/',
    name: 'main',
    meta: { requiresAuth: true },
    component: () => import('@/views/MainView.vue'),
  },
  {
    path: '/cookie-scanner',
    name: 'cookieScan',
    meta: { requiresAuth: true },
    component: () => import('@/views/CookieScanView.vue'),
  },
  {
    path: '/profile/:steamid',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import('@/views/ProfileView.vue'),
  },
  {
    path: '/inventory/:steamid',
    name: 'inventory',
    meta: { requiresAuth: true },
    component: () => import('@/views/InventoryView.vue'),
  },

  {
    path: '/settings',
    name: 'settings',
    meta: { requiresAuth: true },
    component: () => import('@/views/SettingsView.vue'),
  },

  {
    path: '/subscription',
    name: 'subscription',
    meta: { requiresAuth: true },
    component: () => import('@/views/SubscriptionView.vue'),
  },
  {
    path: '/users',
    name: 'users',
    meta: { requiresAuth: true },
    component: () => import('@/views/UsersView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.loading) {
    next();
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login');
      return;
    }
  }
  if (to.matched.some((record) => record.meta.loginView)) {
    if (store.getters.isAuthenticated) {
      next('/');
      return;
    }
  }

  next();
});

export default router;
